import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import { useVirtualAccountHolding, } from '~/modules/virtualExchange/useVirtualExchangeResource';
import { store } from '~/pages/heineken_template/_private/store';
import { VolumeFilter } from './VolumeFilter';
import { boleStock_store } from '../_private/boleStock_store';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { useSnapshot } from 'valtio';
import dayAPI from '~/utils/dayAPI';
export const StockScreener15minute = memo(function StockScreener15minute() {
    const intraDay = useSnapshot(staticStore.tradedDate.intraday).format('YYYY-MM-DD');
    // 排除掉非今日進場的虛擬帳戶持股
    const kdSymbol = useVirtualAccountHolding('bole_stock')?.filter(datum => dayAPI(datum.lastModifiedDatetime).format('YYYY-MM-DD') === intraDay);
    return (<styleds.container>
      <SimpleQuoteListInteractWithChart data={kdSymbol?.map(position => position.symbol) ?? ['']} chart={store.charting} handleClick={event => {
            store.charting.changeInterval(15);
            boleStock_store.userLastFocusSymbol = event;
        }}/>
      <VolumeFilter />
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    display: grid;
    grid-template-rows: calc(100% - 42px) 42px;
    gap: 2px;
  `,
};
