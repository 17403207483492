import styled from '@emotion/styled';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import IntradayTrendChart from '~/modules/trendChart';
import { store } from '~/pages/heineken_template/_private/store';
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { ScreenerButton } from '../component/ScreenerButton';
import { SymbolLink } from '../component/SymbolLink';
import { StockScreener15minute } from '../component/StockScreener15minute';
import { SymbolWatchList } from '../component/SymbolWatchList';
import { boleStock_store } from './boleStock_store';
import { StockScreenerDaily } from '../component/StockScreenerDaily';
import { MdLeaderboard } from 'react-icons/md';
import { AiFillStar } from 'react-icons/ai';
export const BoleStock_SidePane2 = memo(function BoleStock_SidePane2() {
    const charting = useSnapshot(store).charting;
    const symbol = charting.symbol;
    const state = useSnapshot(boleStock_store);
    //待處理:看要一次call api 還是根據點選元件另外call
    //const kdSymbol = useVirtualAccountHolding('bole_stock')?.filter(datum => datum.qty === 1)
    return (<styleds.container>
      <styleds.symbolQuoteContent>
        <TitleSymbolQuote.Default symbol={symbol}/>
      </styleds.symbolQuoteContent>
      <styleds.trendChartContent>
        <IntradayTrendChart symbol={symbol} ticksSize={13} ticksHeight={18} priceTicksMargin={-8} priceTicksSize={13}/>
      </styleds.trendChartContent>
      <styleds.linkContent>
        <SymbolLink />
      </styleds.linkContent>
      <styleds.screenerContent>
        <ScreenerButton condition='default'>
          <MdLeaderboard />
          &nbsp;籌碼選股
        </ScreenerButton>
        <ScreenerButton condition='kdStock'>
          <MdLeaderboard />
          &nbsp;當沖選股
        </ScreenerButton>
        <ScreenerButton condition='userStock'>
          <AiFillStar />
          &nbsp;自選清單
        </ScreenerButton>
      </styleds.screenerContent>
      <styleds.symbolListContent>
        {state.conditions === 'default' && <StockScreenerDaily />}
        {state.conditions === 'kdStock' && <StockScreener15minute />}
        {state.conditions === 'userStock' && <SymbolWatchList />}
      </styleds.symbolListContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    background-color: #333333;
    //border-radius: 4px;
    gap: 4px;
    padding: 4px;
  `,
    symbolQuoteContent: styled.div `
    ${fill_vertical_all_center};
    width: 100%;
    height: 28px;
    border-radius: 4px;
    background-color: #272727;
  `,
    trendChartContent: styled.div `
    width: 100%;
    height: 164px;
    padding: 4px;
    border-radius: 4px;
    background-color: #272727;
  `,
    linkContent: styled.div `
    width: 100%;
    height: 40px;
  `,
    screenerContent: styled.div `
    ${fill_horizontal_all_center};
    height: 32px;
    gap: 4px;
  `,
    symbolListContent: styled.div `
    ${fill_vertical_cross_center};
    height: calc(100% - 282px);
  `,
};
